import React from 'react'
import PropTypes from 'prop-types';
import Link from 'next/link'
import styled from 'styled-components'
import Login from '../components/Login'
import Signup from '../components/Signup'
import Modal from '../utils/modal'
import Head from 'next/head'
import { updateUser } from '../store'
import api from '../utils/api'
import redirect from "../utils/redirect"
import HeaderNegocio from '../components/HeaderNegocio'
import AgendaWithSlider from '../components/AgendaWithSlider'
import moment from 'moment'
import update from 'immutability-helper'
import _ from 'lodash'
import Calendar from 'react-day-picker'
import gql from "graphql-tag"
import { Query, withApollo, graphql } from "react-apollo"
import Router from 'next/router'

import defaultPage from '../hocs/defaultPage'

import config from '../config'

const WrapperHorizontal = styled.div`
display: flex;
flex-direction: row;
`

const BotonService = styled.a`
    ${props => !props.selected && "display:none;"}
  background-color: ${props => props.selected ? "#0064a3" : "#0a4373"};
  font-size: 14px;
    color: #fff;
    margin: 5px 7px 10px 7px;
    padding: 7px 24px;
    text-align: center;
    border-radius: 25px;
    display:${props => props.selected ? "flex" : "none"};
    align-items: center;
    position:relative;
`

const BotonDoctor = styled(BotonService)``

const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
    color: #fff
`

const TitleAgenda = styled.div`
text-align: center;
    font-weight: 600;
    font-size: 20px;
    font-family: "Helvetica Neue", Helvetica;
    color: #888;
`
const Subtitle = styled(TitleAgenda)`
font-weight: 400;
font-size: 15px;
color: #737373;
`

const MainContainer = styled.div`
    min-height:100vh;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', Helvetica, Roboto; 
    display: flex;
    flex-direction: row;
    padding: 20px; 
    background-color: #091923;
    align-items: flex-start; 
    @media only screen and (max-width: 700px){
        flex-direction:column;
        align-items: stretch; 
        padding:0 0 20px;
    }
`

const InfoContainer = styled.div`
    margin-right: 20px; 
    font-family: 'Helvetica Neue', Helvetica, Roboto; 
    color:#e8e8e8;
    width:350px;
    @media only screen and (max-width: 700px){
        width: auto;
        margin-right:0;
        margin-bottom:20px;
        padding: 20px 20px 0 20px;
    }
`

const WrapperHorizontalCentrado = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const InfoServiceContainer = styled.div`
    white-space: nowrap;

    & div{
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

const Cross = styled.div`
    margin: 0 -9px 0 11px;
    font-size: 24px;
`

const ServiciosContainer = styled.div`
    background-color: rgb(239, 239, 239);
    margin: 15px;
    border-radius: 20px;
    padding: 10px 20px 5px;
`

const TitleServicios = styled.div`
font-size: 17px;
    color: #1062a8;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
`

const BotonCalendarContainer = styled.div`
    text-align: center;
    margin-bottom: -5px;
    margin-top: 15px;
    padding-top: 3px;
    display: flex;
    justify-content: center;
`

const BotonCalendar = styled.a`
width: 123px;
    display: inline-block;
    border-radius: 24px;
    padding: 5px 0;
    color: #4c4c4c;
    border: 2px solid #cacaca;
    font-size: 20px;
`

const BotonHoy = styled(BotonCalendar)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size:14px;
    margin-right: 10px;
    width:85px;
    font-weight: 500;
    ${props => props.today && "opacity: .4; cursor: default;"}
`

const CalendarModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
`

const BotonCalendarModal = styled.div`
   color: ${props => props.disabled ? "#5f5f5f" : "#fff"};
    font-size: 40px;
    padding: 10px;
    margin: 0 -10px;
    cursor:${props => props.disabled ? "auto" : "pointer"};
`

const ModalCitaContainer = styled.div`
    display: flex;
    flex-direction:column;
`

const ModalCitaTitle = styled.div`
        text-align: center;
    margin: 5px 0 15px;
    font-weight: 500;
    color: #003a88;
    font-size: 17px;
`

const ValueModalCita = styled.div`
margin-top: 3px;
color: #191919;
font-size: 16px;
`

const LabelCitaModal = styled.div`

`

const RowCitaModal = styled.div`
    ${props => {
        if (!props.edit)
            return `margin-bottom: 11px;
            padding: 0 10px 11px;
            border-bottom:1px solid #e8e8e8;`}}
    & ${LabelCitaModal}{
        ${props => {
        if (!props.edit)
            return `color: #868686;
            font-size: 14px;`
    }}
    }
`

const HorariosModal = styled.div`
        color: #868686;
    font-weight: 400;
    margin-top: 5px;
`

const MoreInfoRow = styled.a`
    text-decoration:none;
    color:#e8e8e8;
    margin-Bottom: 7px;
    display:flex;
    flex-direction:row;
    align-items:center;
    .no-touch &:hover{
        color:#16a5ff;;
    }
    & i,svg{
        margin-right:7px;
        font-size:19px;
    }
`

const citaQuery = gql`
query($code: String){
  cita(code:$code){
    id
    name
    email
    start_time
    duration
    service
    status
    requirementsDates {
      value
      name
    }
  }
}
`

class Negocio extends React.Component {

    static async getInitialProps(ctx, apolloClient) {
        console.log("getting initial props negocio.js")
        const json = await api.get('/business', {
            queryParams: {
                subdomain: ctx.query.subdomain
            }
        })
        if (!json) {
            console.log("redirecting")
            redirect(ctx, "http://" + config.domainWithPort, { type: "replace" })
        }
        let cita = null
        if (ctx.query.code) {
            const data = await apolloClient.query({
                query: citaQuery,
                variables: {
                    code: ctx.query.code
                }
            })
            cita = data.data.cita
            console.log(data, cita)
        }
        return {
            cita,
            business: json
        }
    }

    constructor(props) {
        super(props)
        this.changeDate = this.changeDate.bind(this)
        this.selectService = this.selectService.bind(this)
        this.deselectService = this.deselectService.bind(this)
        this.selectDoctor = this.selectDoctor.bind(this)
        this.deselectDoctor = this.deselectDoctor.bind(this)
        this.state = {
            confirmacion: props.cita && { ...props.cita, start_time: moment(props.cita.start_time) },
            doctor: null,
            business: props.business.type != 1 ? props.business : null,
            service: !!!props.business.use_services ? { duration: props.business.duration } : props.business.services.length == 1 ? props.business.services[0] : null
        }
        this.business = props.business
    }

    changeDate(newDate) {
        this.setState({
            date: newDate
        })
    }

    selectService(service) {
        if (this.state.service && service.id == this.state.service.id)
            this.deselectService()
        else
            this.setState({
                service: service
            })
    }

    deselectService() {
        this.setState({
            service: null
        })
    }

    selectDoctor(doctor) {
        if (doctor != this.state.doctor)
            this.setState({
                doctor: doctor,
                service: doctor.services.length == 1 && doctor.services[0] || null,
                business: update(doctor, {
                    id: { $set: doctor.businesses_id },
                    doctors_id: { $set: doctor.id },
                    type: { $set: 0 },
                    simultaneous: { $set: 1 },
                    use_services: { $set: 1 },
                    requirements: { $set: this.props.business.requirements },
                    min_span: { $set: this.props.business.min_span },
                    max_span: { $set: this.props.business.max_span },
                })
            })
        else
            this.deselectDoctor()
    }

    deselectDoctor() {
        this.setState({
            doctor: null,
            business: this.business,
            service: null
        })
    }

    componentDidMount() {
        if (this.props.business.type == 1 && this.props.business.doctors.length == 1)
            this.selectDoctor(this.props.business.doctors[0])
        const today = moment()
        this.setState({
            today: today,
            date: today,
            width: this.agendaContainer && this.agendaContainer.offsetWidth
        })

        window.addEventListener("resize", _.debounce(() => {
            if (this.agendaContainer) {
                console.log("resizing", this.agendaContainer.offsetWidth)
                this.setState({
                    width: this.agendaContainer.offsetWidth
                })
            }
        }, 250));
    }

    goToday = () => {
        const today = moment()
        this.setState({ date: today })
    }

    openBotonCalendarModal = () => {
        this.setState({
            botonCalendarDate: this.state.date.toDate()
        })
    }

    closeBotonCalendarModal = () => {
        this.setState({
            botonCalendarDate: null
        })
    }

    handleDayClick = (day, modifiers) => {
        console.log(modifiers)
        if (!modifiers.disabled)
            this.setState({
                botonCalendarDate: null,
                date: moment(day)
            });
    }

    clickArrowCalendarModal = (sum) => {
        const date = new Date(this.state.botonCalendarDate.getTime())
        date.setMonth(date.getMonth() + sum)
        this.setState({
            botonCalendarDate: date
        })
    }

    openConfirmacion = (date) => {
        date.start_time = moment(date.start_time)
        this.setState({
            confirmacion: date
        })
        const href = '/negocio?business=' + this.business.subdomain + '&code=' + date.code
        const as = "/?code=" + date.code
        Router.push(href, as, { shallow: true })
    }

    render() {
        const today = moment()
        const business = this.props.business
        const services = business && business.type != 1 ? business.services : this.state.doctor && this.state.doctor.services
        const selectedIsToday = this.state.date && this.state.date.isSame(this.state.today, "day")
        const botonCalendarDateMoment = moment(this.state.botonCalendarDate)
        const disableLeftArrowCalendar = botonCalendarDateMoment.isSame(this.state.today, "month")
        return (<div>
            <Modal style={{ backgroundColor: "#0a1924", width: "auto" }} visible={!!this.state.botonCalendarDate} onClose={this.closeBotonCalendarModal} >
                <CalendarModalContainer>
                    <BotonCalendarModal disabled={disableLeftArrowCalendar} onClick={() => { !disableLeftArrowCalendar && this.clickArrowCalendarModal(-1) }} ><i className="fa fa-chevron-left"></i></BotonCalendarModal>
                    <Calendar disabledDays={{
                        before: new Date(),
                    }} selectedDays={this.state.date && this.state.date.toDate()} month={this.state.botonCalendarDate} canChangeMonth={false} onDayClick={this.handleDayClick} />
                    <BotonCalendarModal onClick={() => { this.clickArrowCalendarModal(+1) }} ><i className="fa fa-chevron-right"></i></BotonCalendarModal>
                </CalendarModalContainer>
            </Modal>
            <Head>
                <title>Aggendi</title>
            </Head>
            <MainContainer>
                <InfoContainer>
                    <div style={{ padding: business.logo ? "10px" : "10px 0", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                        {business.logo && <img style={{ width: 150, height: 150, borderRadius: 3, marginRight: 30 }} src={config.api + "/static/logos/" + business.logo} alt="" />}
                        <div style={{ fontFamily: "Open Sans", fontWeight: 700, fontSize: 20, color: "#fff" }} >{business.name}</div>
                    </div>
                    <div style={{ marginBottom: 20, lineHeight: "20px" }} dangerouslySetInnerHTML={{ __html: business.about }} >
                    </div>
                    <div style={{ marginBottom: 20 }} >
                        <Title>Dirección</Title>
                        <div>{business.address}</div>
                    </div>
                    <div >
                        <Title>Mas información</Title>
                        {business.phone && <MoreInfoRow href={"tel:" + business.phone} ><i className="fas fa-phone"></i>{business.phone}</MoreInfoRow>}
                        {business.website && <MoreInfoRow href={business.website} ><i className="fas fa-globe-americas"></i>{business.website}</MoreInfoRow>}
                        {business.email && <MoreInfoRow href={"mailto:" + business.email} ><i className="fas fa-envelope"></i>{business.email}</MoreInfoRow>}
                        {business.facebook && <MoreInfoRow href={"https://facebook.com/" + business.facebook} ><i className="fab fa-facebook-square"></i>{this.props.business.facebook}</MoreInfoRow>}
                        {business.twitter && <MoreInfoRow href={"https://twitter.com/" + business.twitter} ><i className="fab fa-twitter"></i>{this.props.business.twitter}</MoreInfoRow>}
                        {business.instagram && <MoreInfoRow href={"https://instagram.com/" + business.instagram} ><i className="fab fa-instagram"></i>{this.props.business.instagram}</MoreInfoRow>}
                    </div>
                </InfoContainer>
                <div style={{ flex: 1, overflow: "hidden", minWidth: 250, backgroundColor: "#fff", display: "flex", flexDirection: "column", padding: "20px 0", borderRadius: 3 }} >

                    {this.state.confirmacion && <div>
                        {this.state.confirmacion.status == "confirmed" && <><TitleAgenda>{business.type != 0 ? "¡Su cita se ha reservado con éxito!" : "Su reservación se ha hecho con éxito"}</TitleAgenda>
                            <ServiciosContainer>
                                <ModalCitaContainer>
                                    <ModalCitaTitle>{this.state.confirmacion.start_time.format("dddd D MMMM, YYYY")}
                                        <HorariosModal>{this.state.confirmacion.start_time.format("HH:mm")} - {this.state.confirmacion.start_time.clone().add(this.state.confirmacion.duration, "minutes").format("HH:mm")}
                                            <div>
                                                {this.state.confirmacion.service}
                                                {this.state.doctor && <span style={{ fontWeight: 500, color: "#696969" }} > {this.state.doctor.name} </span>}
                                            </div>
                                        </HorariosModal>
                                    </ModalCitaTitle>
                                    <RowCitaModal edit={false}><LabelCitaModal>Nombre</LabelCitaModal>
                                        <ValueModalCita>{this.state.confirmacion.name}</ValueModalCita>
                                    </RowCitaModal>
                                    <RowCitaModal edit={false}><LabelCitaModal>Email</LabelCitaModal>
                                        <ValueModalCita>{this.state.confirmacion.email}</ValueModalCita>
                                    </RowCitaModal>
                                    {this.state.confirmacion.requirementsDates.map((requirement, i) =>
                                        <RowCitaModal key={i} edit={false}><LabelCitaModal>{requirement.name}</LabelCitaModal>
                                            <ValueModalCita>{requirement.value}</ValueModalCita>
                                        </RowCitaModal>
                                    )}
                                </ModalCitaContainer>
                            </ServiciosContainer>
                        </> || <>
                                <TitleAgenda>
                                    Su {business.type != 0 ? "cita" : "reservación"} esta pendiente de aprobación por el negocio
                            </TitleAgenda>
                                <div style={{ textAlign: "center", fontSize: 90, margin: "30px 0 20px", color: "#01aad4" }} >
                                    <i className="far fa-clock"></i>
                                </div>
                                <div style={{ margin: "0 auto", maxWidth: 400, textAlign: "center", marginBottom: 30, color: "#2b2b2b", fontSize: 19, fontFamily: 'Roboto, "Helvetica Neue", Helvetica' }}>Se le notificara por correo electronico una vez que el negocio confirme su {business.type != 0 ? "cita" : "reservación"}</div>
                            </>}

                        <Subtitle>Se ha enviado una copia de esta información a su correo
                        <div style={{ marginTop: 2, fontSize: 13, color: "#ad0000" }}>Revisar tambien bandeja de correo no deseado</div>
                        </Subtitle>

                    </div> ||
                        <Query query={gql`query suscription($businesses_id: Int){
                        suscription(businesses_id: $businesses_id){
                            id
                        }
                    }`} variables={{ businesses_id: business.id }} >
                            {({ loading, error, data: { suscription } = {}, refetch }) => {

                                if (!suscription && !loading) {
                                    return <div><TitleAgenda>Calendario temporalmente desactivado</TitleAgenda>
                                        <Subtitle style={{ margin: "30px 30px 20px", lineHeight: "25px" }}>Este negocio tiene su calendario temporalmente desactivado, para agendar una {business.type != 0 ? "cita" : "reservación"} comuniquese directamente por telefono</Subtitle>
                                    </div>
                                }
                                return <div ref={ref => this.agendaContainer = ref} >
                                    <TitleAgenda>Agendar {business.type != 0 ? "cita" : "reservación"}</TitleAgenda>
                                    {business.type == 1 && business.doctors.length > 1 && <ServiciosContainer>
                                        <TitleServicios>Seleccione un doctor</TitleServicios>
                                        <WrapperHorizontalCentrado>
                                            {business.doctors.map((doctor, index) => <BotonService style={{ height: 41, boxSizing: "border-box" }} key={index} onClick={() => { this.selectDoctor(doctor) }} selected={this.state.doctor && doctor.id == this.state.doctor.id || !this.state.doctor}>
                                                <InfoServiceContainer >
                                                    <div>{doctor.name}</div>
                                                </InfoServiceContainer>
                                                {this.state.doctor && doctor.id == this.state.doctor.id && <Cross>
                                                    <i className="fa fa-times"></i>
                                                </Cross>}
                                            </BotonService>)}
                                        </WrapperHorizontalCentrado>
                                    </ServiciosContainer>}
                                    {(business.use_services == 1 || this.state.doctor) && services && services.length > 1 && <ServiciosContainer>
                                        <TitleServicios>Seleccione un servicio</TitleServicios>
                                        <div style={{ position: "relative" }}>
                                            <WrapperHorizontalCentrado>
                                                {services.map((service, index) => <BotonService key={index} onClick={() => { this.selectService(service) }} selected={this.state.service && service.id == this.state.service.id || !this.state.service}>
                                                    <InfoServiceContainer crossed={this.state.service && service.id == this.state.service.id} >
                                                        <div>{service.name}</div><div>({service.duration}) minutos</div>
                                                    </InfoServiceContainer>
                                                    {this.state.service && service.id == this.state.service.id && <Cross>
                                                        <i className="fa fa-times"></i>
                                                    </Cross>}
                                                </BotonService>)}
                                            </WrapperHorizontalCentrado>
                                        </div>
                                    </ServiciosContainer>}
                                    {this.state.service && this.state.date && this.state.business && <div>
                                        <BotonCalendarContainer ><BotonCalendar onClick={this.openBotonCalendarModal} ><i className="far fa-calendar-alt"></i></BotonCalendar></BotonCalendarContainer>
                                        <AgendaWithSlider callbackOnSaveCita={this.openConfirmacion} today={today} width={this.state.width} hideCitas={true} user={this.props.user} onChange={this.changeDate} business={this.state.business} service={this.state.service} date={this.state.date} />
                                    </div>
                                    }
                                </div>
                            }}
                        </Query>
                    }

                </div>
            </MainContainer>
        </div>)
    }

}

export default defaultPage(Negocio)
