import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { logout } from '../utils/auth'
import { withApollo } from "react-apollo"

import config from '../config'

const AppHeader = styled.div`
  display: flex;
  color: #fff;
  justify-content:flex-end;
    left: 0;
    right: 0;
  z-index:1;
    align-items: center;
    @media only screen and (max-width: 755px){
        height: auto;
    position: relative;
    padding: 20px 0;
    }

`

const Saludo = styled.div`
      color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: 700;
    text-align: center;
    padding: 13px;
    grid-column-start: 2;
    background-color: #074a95;
`

const Logo = styled.img`
display:block;
margin-left: 50px;
margin-right: 50px;
cursor: pointer;
@media only screen and (max-width: 755px){
    display: none;
}
`

const LogoReduced = styled.img`
display:none;
@media only screen and (max-width: 755px){
    display: block;
    margin-left: 30px;
    align-self: flex-start;
    cursor: pointer;
}
`

const LogoBusiness = styled.img`
    border-radius: 100%;
    height:100px;
    align-self: center;
    margin-bottom: -8px;
    z-index: 1;
    margin-top:8px;
    cursor:pointer;
    @media only screen and (max-width: 755px){
        position: absolute;
    top: -64px;
    height:64px;
    }
`

const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Helvetica Neue", Helvetica, Roboto;
  align-items: flex-end;
  margin: 0px 30px 0 0;
  font-size: 17px;
  @media only screen and (max-width: 755px){

      flex-direction: row;
        
  }
`

const Icon = styled.i`
margin-right: 5px;
`

const Item = styled.a`
margin: 0 0 22px 0;
    text-decoration: none;
    color: #fff;
    &:last-child{
        margin: 0 0px;
    }
    @media only screen and (max-width: 755px){
        margin: 0 20px 0 0;
        font-size: 20px;
        &:last-child{
        margin: 0 0px;
    }
        & span {
            display:none;
        }
    }
`

const LogoContainer = styled.div`
@media only screen and (max-width: 755px){
    flex: 0.25;
    display: flex;
    justify-content: space-around;
}

`

const ConfigMenu = styled.div`
    display: flex;
    flex-direction: column;
`

const NameWrapper = styled.div`
display: flex;
    flex-direction: column;
    position: relative;
    @media only screen and (max-width: 510px){
background-color: #074a95;
display: grid;
grid-template-columns: 1fr auto 1fr;
    justify-items: center;
    }
`
const Container = styled.div`
    height:50px;
    position: relative;
    display: flex;
    flex-direction: column;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#200d42+0,005172+100 */
background: #200d42; /* Old browsers */
background: -moz-linear-gradient(left, #200d42 0%, #005172 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, #200d42 0%,#005172 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, #200d42 0%,#005172 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#200d42', endColorstr='#005172',GradientType=1 ); /* IE6-9 */

`

const HistorialButton = styled.div`
display:none;
    @media only screen and (max-width: 510px){
        cursor: pointer;
        justify-self: flex-end;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 23px;
    color: #fff;
    width: 50px;
    padding-left: 10px;
    }
`

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.logout = () => logout(props.client)
    }

    render() {
        const business = this.props.business
        return <Container>
            <AppHeader>
                <Link href="/" prefetch>
                    <LogoContainer>
                        <Logo width={150} src={config.api + "/static/logo_white.png"} alt="" />
                        <LogoReduced height={25} src={config.api + "/static/logo_reduced.png"} alt="" />
                    </LogoContainer>
                </Link>
            </AppHeader>
        </Container>
    }
}

export default withApollo(Header)
